function Share({ label, text, title }) {
    const canonical = document.querySelector("link[rel=canonical]");
    let url = canonical ? canonical.href : document.location.href;
    const shareDetails = { url, title, text };
  
    const handleSharing = async () => {
      // Check if Web Share API is supported
      if (window.Android) {
          // Use the Android interface method to share content
          // Content for sharing (formatted text, emojis, and a link)
          const title = 'Tamil Sol Game Result';
          const text = 
              '#845 தமிழ் சொல் 3/6\n' +   // Game result title
              '🟨❌🟨❌🟨🟢\n' +           // First row with icons (Yellow, Red Cross, Green Circle)
              '🟢❌🟢❌🟨❌\n' +           // Second row
              '🟨🟢🟨🟢❌🟢\n' +           // Third row
              '🟢❌🟢❌🟢🟢\n' +           // Fourth row
              '❌🟢❌🟨🟢❌\n' +           // Fifth row
              '🟢🟨❌🟢🟨🟢\n\n' + 
              '#tamil #sol #சொல்\n'; // Hashtags
          const url = 'https://tamilwordle.net/?pfrom=share'; // Link

          // Call the Android share method, passing title, text, and URL
          window.Android.share(title, text, url);
      } else {
          alert('Share is not supported in this environment.');
      }
    };
    return (
      <button className="sharer-button  share correct" onClick={handleSharing}>
        <span className="sharer-button-text">{label}</span>
      </button>
    );
  }
  export default Share;