import './App.css';
import React, { useRef , useEffect, useState } from 'react';
import { AiFillSetting } from "react-icons/ai";
import { BsBarChartLineFill } from "react-icons/bs";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { BsBackspace } from "react-icons/bs"
import { AiOutlineEnter } from "react-icons/ai";
 import { AiOutlineClose } from "react-icons/ai";
import { GiBee } from "react-icons/gi";
import { GiShare } from "react-icons/gi";
import { GoThreeBars } from "react-icons/go";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myfn from './AppFunctions.js';
import axios from "axios";
import { useCookies } from 'react-cookie';
import { useParams } from "react-router-dom";
import Share from "./Share";

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const hour = today.getHours();
  const minute = today.getMinutes();
  return `${date}/${month}/${year} ${hour}:${minute}`;
}

function App() {
  const [currentDate, setCurrentDate] = useState(getDate());
  const [ip, setIP] = useState("");
  const dataFetchedRef = useRef(false);
  const [hintletters, sethintLetters] = useState(null); 
  const [gameDetails, setgameDetails] = useState(null); 
  const queryParameters = new URLSearchParams(window.location.search);
  const userid = 0;
  if (queryParameters.has('userid')) {
    //console.log(queryParameters.has('userid'));
    const userid = queryParameters.get("userid");
    localStorage.setItem("useridd", queryParameters.get("userid"));
  } else {
    localStorage.setItem("useridd", 0);
  }

  if (queryParameters.has('uniqueid')) {
    //console.log(queryParameters.has('uniqueid'));
    const useruniqueid = queryParameters.get("uniqueid");
    localStorage.setItem("useruniqueid", queryParameters.get("uniqueid"));
  } else {
    localStorage.setItem("useruniqueid", 0);
  }
  
  

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
    localStorage.setItem("ip", res.data.ip);
  };

  useEffect(() => {
    gethintLetters();
    getData();
    getgameDetails();
  }, []);

  

  function gethintLetters() {
    axios.get('https://tamilwordle.net/WordGameApi/hintletters.php').then(function(response) {
        console.log(response.data);
        sethintLetters(response.data);
    });
  }

  function getgameDetails() {
    const playedurl = "https://tamilwordle.net/WordGameApi/getPlayedDetails.php";

    let playedData = new FormData();
    if (queryParameters.has('userid')) {
      console.log(queryParameters.has('userid'));
      playedData.append('userid', queryParameters.get("userid"));
      //localStorage.setItem("useridd", queryParameters.get("userid"));
    } else {
      playedData.append('userid', queryParameters.get("userid"));
    }
    
    axios.post(playedurl, playedData).then(function(response) {
      console.log(response.data);
      setgameDetails(response.data);
    });
  }
  
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    myfn.initialFunction(localStorage.getItem("ip")); 
    console.log("here");

    
  }, []);

  
    //var statistics=JSON.parse(localStorage.getItem("statistics"));
    var statistics={"TotalPlayed":5,"Win":3,"Loss":2,"Streak":1,"MaxStreak":1,"Guess":[0,2,0,1,0]};
    //var statistics=localStorage.getItem("statistics");
    //console.log("statistics:"+statistics);

  return (
    
    <div className="App" tabIndex="0" onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => myfn.handleKeyDown(e.key)}>
      {/* <Share
                  label="Share"
                  title="My Web Share Adventures"
                  text="Hello World! I shared this content via Web Share"
                /> */}
      {/* <div className="game-header">
     
          <nav className="navbar navbar-light fixed-top">
            <div className="container-fluid">
            <a className="navbar-brand" href="#"><img src="https://wjoblist.com/game_wordle/tamilwordle.png" alt="React Image" /></a>
              <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                <span className="navbar-toggler-icon"></span>
                <BsBarChartLineFill size={30}/>
              </button>
              
              <div className="side-buttons">
                <button className="side-buttons-icons" type="button" data-bs-toggle="modal" data-bs-target="#how-modal1">
                  <BsFillQuestionCircleFill size={30}/>
                </button>
                <button className="side-buttons-icons px-3" type="button" data-bs-toggle="modal" data-bs-target="#statistics-modal1">
                  <BsBarChartLineFill size={30}/>
                </button>
                <button className="side-buttons-icons" type="button" data-bs-toggle="modal" data-bs-target="#settings-modal1">
                  <AiFillSetting size={30}/>
                </button>
              </div>
              <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">Games</h5>
                  <button type="button" className="dismiss-button" data-bs-dismiss="offcanvas" aria-label="Close">
                    <AiOutlineClose/>
                  </button>
                </div>
                <div className="offcanvas-body">
                  <ul className="navbar-nav justify-content-start flex-grow-1 pe-3">
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Archive</a>
                    </li>
                  
                  </ul>
                  
                </div>
                <div className="offcanvas-footer">
                  <small className="copyright text-center">
                    Made with ❤️ | Website developed by Rathnasoft
                  </small><br></br>
                  
                </div>
              </div>
            </div>
          </nav> 

      </div> */}

      {/* Settings Modal */}
      <div className="modal" id="settings-modal" tabIndex="-2" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <div className="flex-div"></div>
              <h5 className="modal-title" id="exampleModalLabel">SETTINGS</h5>
              <div className="flex-div"></div>
              <button type="button" className="dismiss-button" data-bs-dismiss="modal" aria-label="Close"><AiOutlineClose/></button>
              {/* <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">
                <AiOutlineClose/>
              </button> */}
            </div>
            <div className="modal-body">
              <div className="settings-options">
                <div className="settings-options-texts">
                  <div>
                    Hard Mode
                  </div>
                  <div>
                    <small>
                      Any revealed hints must be used in subsequent guesses
                    </small>
                  </div>
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" onClick={myfn.hardmodeButton} id="hardModeSwitch"/>
                </div>
              </div>

              <div className="settings-options">
                <div className="settings-options-texts">
                  <div>
                    Dark Theme
                  </div>
                  <div>
                   
                  </div>
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" onClick={myfn.themeButton} id="darkThemeSwitch"/>
                </div>
              </div>

              <div className="settings-options">
                <div className="settings-options-texts">
                  <div>
                    High Contrast Mode
                  </div>
                  <div>
                    <small>
                      For improved color vision
                    </small>
                  </div>
                </div>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" onClick={myfn.colorBlindButton} id="highContrastMode"/>
                </div>
              </div>

              <div className="settings-options">
                <div className="settings-options-texts">
                  <div>
                    Feedback
                  </div>
                </div>
                <div className="settings-options-side">
                    Email
                </div>
              </div>

              <div className="settings-options">
                <div className="settings-options-texts">
                  <div>
                    Community

                  </div>
                </div>
                <div className="settings-options-side">
                    Twitter
                </div>
              </div>

              <div className="settings-options">
                <div className="settings-options-texts">
                  <div>
                    Questions?
                  </div>
                </div>
                <div className="settings-options-side">
                    FAQ
                </div>
              </div>
              <div>
                <small>
                Made with ❤️ | Website developed by S Subodh
                </small>
              </div>
              

            </div>
            {/* <div className="modal-footer">
              <small className="copyright text-center">
                  Made with ❤️ | Website developed by S Subodh
              </small>
              <small>
                This website is developed only for Educational purpose
              </small>
            </div> */}
          </div>
        </div>
      </div>

      {/* How to play Modal */}
      <div className="modal" id="how-modal" tabIndex="-3" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header pb-0">
              <div className="flex-div"></div>
              <button type="button" className="dismiss-button" data-bs-dismiss="modal" aria-label="Close">
                <AiOutlineClose/>
              </button>
            </div>
            <div className="modal-body">
              <div className="how-options">
                <h3>How To Play</h3>
                
                <h5>Guess the Wordle in 6 tries.</h5>

                <div className="bullets">
                  <ul>
                    <li>Each guess must be a valid 5-letter word.</li>
                    <li>The color of the tiles will change to show how close your guess was to the word.</li>
                  </ul>
                </div>
                <div className="examples  mb-3">
                  <div>Examples</div>
                  <div className="how-row">
                    <div className="cube exm correct col-2">W</div>
                    <div className="cube exm col-2">E</div>
                    <div className="cube exm col-2">A</div>
                    <div className="cube exm col-2">R</div>
                    <div className="cube exm col-2">Y</div>
                  </div>
                  <div>W is in the word and in the correct spot.</div>
                </div>
                <div className="examples mb-3">
                  <div className="how-row">
                    <div className="cube exm col-2">P</div>
                    <div className="cube exm present col-2">I</div>
                    <div className="cube exm col-2">L</div>
                    <div className="cube exm col-2">L</div>
                    <div className="cube exm col-2">S</div>
                  </div>
                  <div>I is in the word but in the wrong spot.</div>
                </div>
                <div className="examples mb-3">
                  <div className="how-row">
                    <div className="cube exm col-2">V</div>
                    <div className="cube exm col-2">A</div>
                    <div className="cube exm col-2">G</div>
                    <div className="cube exm absent col-2">U</div>
                    <div className="cube exm col-2">E</div>
                  </div>
                  <div>U is not in the word in any spot.</div>
                </div>
              
              </div>

              <div>
                <div className="how-footer">
                  A new puzzle is released daily at midnight. If you haven’t already, you can <a href = "#">sign up</a> for our daily reminder email.
                </div>
                <div className="how-footer">
                  Have feedback? Email us at <a href = "#">xyz@xyz.com</a>.
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* Statistics Modal */}
      <div className="modal" id="statistics-modal" tabIndex="-2" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header pb-0">
              <div className="flex-div"></div>
              
              <button type="button" className="dismiss-button" data-bs-dismiss="modal" aria-label="Close"><AiOutlineClose/></button>
            </div>
            <div className="modal-body pt-0">
              <div className="statistics-options">

                <div>
                  <center id='congratsimg'><img src="https://tamilwordle.net/game_wordle/congrats.png" /></center>
                  <center><h5 className="modal-title" id="gamestatus">வாழ்த்துக்கள்</h5></center>
                  <center><div className="modal-title" id="statistics">STATISTICS</div></center>
                </div>
                <div className="stats-boxes">
                  <div className="stats-box">
                    <div id="stats-num" className="stats-num">
                      
                    </div>
                    <div className="stats-text">
                      Played
                    </div>
                  </div>

                  <div className="stats-box">
                    <div id="wincount" className="stats-num">
                      
                    </div>
                    <div className="stats-text">
                      Win %
                    </div>
                  </div>

                  {/* <div className="stats-box">
                    <div className="stats-num">
                      { statistics.Streak }
                    </div>
                    <div className="stats-text">
                      Current Streak
                    </div>
                  </div>

                  <div className="stats-box">
                    <div className="stats-num">
                      { statistics.MaxStreak }
                    </div>
                    <div className="stats-text">
                      Max Streak
                    </div>
                  </div> */}

                </div>

                <p className="wrd"><strong>சரியான சொல்<br /><span className='correctword' id='correctword'></span></strong></p>

                <div className="guessdiv mt-2 mb-1">
                  <h6 className="stats-title" id="exampleModalLabel">GUESS DISTRIBUTION</h6>
                  
                  <div className="statsData">
                  {gameDetails && gameDetails.map(l => {
                  return (
                    <div className='statsData-box'>
                      <div className='guess-num'>{l.index}</div>
                      {
                        l.Guess==0
                        ?
                        <div className='guess-val'>{l.Guess}</div>
                        :
                        <div className='guess-val guess-value'>{l.Guess}</div>
                      }
                    </div>
                  )
                })}
                  
                  </div>  


                  <div>

                  </div>
                                    
                </div>

              </div>
              
              <div className="mt-1">
                NEXT WORDLE IN <span id='nextGameTimer'></span>
                <Share
                  label="Share"
                  title="My Web Share Adventures"
                  text="Hello World! I shared this content via Web Share"
                />
                {/* <button className="button share correct" onClick={myfn.onShare} type="button">Share <GiShare/></button> */}
              </div>
              {/* <div className="mt-1 stats-footer">
                    
                    <button className="button share correct" type="button">Share <GiShare/></button>
                  
              </div> */}
              

            </div>
          </div>
        </div>
      </div>
      
      <div className="next-head">
        
      <div className="home"><a href='#' target="_blank"><img src="https://tamilwordle.net/game_wordle/home.png" alt="React Image" /></a></div>
        <div className="div1"><img src="https://tamilwordle.net/game_wordle/calendar.png" alt="React Image" /><img src="https://tamilwordle.net/game_wordle/calendar.png" alt="React Image" />{currentDate} </div>
        {/* <div className="div2"><img src="https://wjoblist.com/game_wordle/clock.png" alt="React Image" /><span className='timerdiv'>0.00</span></div> */}
        <div className=""><img src="https://tamilwordle.net/game_wordle/tamilsollogo.png" alt="React Image" /></div>
      </div>
      
      
      <div className="game-body">
        
          <div className="Board-module">
            <div className="Board-module-board">
              <div className="board-row">
              {/* <h4>{ip}</h4> */}
              {hintletters && hintletters.map(l => {
                return (
                  <div className="cube line11 col-2">{l.key_char}</div>
                )
              })}
                {/* <div className="cube line11 col-2">.</div>
                <div className="cube line11 col-2">.</div>
                <div className="cube line11 col-2">அ</div>
                <div className="cube line11 col-2">.</div> */}
              </div>
              <div className="board-row">
                <div className="cube line1 col-2"></div>
                <div className="cube line1 col-2"></div>
                <div className="cube line1 col-2"></div>
                <div className="cube line1 col-2"></div>
                <div className="cube line1 col-2"></div>
              </div>
              <div className="board-row">
                <div className="cube line2 col-2"></div>
                <div className="cube line2 col-2"></div>
                <div className="cube line2 col-2"></div>
                <div className="cube line2 col-2"></div>
                <div className="cube line2 col-2"></div>
              </div>
              <div className="board-row">
                <div className="cube line3 col-2"></div>
                <div className="cube line3 col-2"></div>
                <div className="cube line3 col-2"></div>
                <div className="cube line3 col-2"></div>
                <div className="cube line3 col-2"></div>
              </div>
              <div className="board-row">
                <div className="cube line4 col-2"></div>
                <div className="cube line4 col-2"></div>
                <div className="cube line4 col-2"></div>
                <div className="cube line4 col-2"></div>
                <div className="cube line4 col-2"></div>
              </div>
              <div className="board-row">
                <div className="cube  line5 col-2"></div>
                <div className="cube  line5 col-2"></div>
                <div className="cube  line5 col-2"></div>
                <div className="cube  line5 col-2"></div>
                <div className="cube  line5 col-2"></div>
              </div>
              <div className="board-row">
                <div className="cube line6 col-2"></div>
                <div className="cube line6 col-2"></div>
                <div className="cube line6 col-2"></div>
                <div className="cube line6 col-2"></div>
                <div className="cube line6 col-2"></div>
              </div>
            </div>
            
          </div>
          <div className="Keyboard-module">
            <div id="keyboard-cont">
                <div className="first-row">
                    <button className="keyboard-button" value="13" id="க்"  onClick={myfn.buttonclicked}>க்</button>
                    <button className="keyboard-button" value="39" id="ச்"  onClick={myfn.buttonclicked}>ச்</button>
                    <button className="keyboard-button" value="65" id="ட்"  onClick={myfn.buttonclicked}>ட்</button>
                    <button className="keyboard-button" value="91" id="த்"  onClick={myfn.buttonclicked}>த்</button>
                    <button className="keyboard-button" value="117" id="ப்"  onClick={myfn.buttonclicked}>ப்</button>
                    <button className="keyboard-button" value="221" id="ற்"  onClick={myfn.buttonclicked}>ற்</button>
                    <button className="keyboard-button" value="143" id="ய்"  onClick={myfn.buttonclicked}>ய்</button>
                    <button className="keyboard-button" value="156" id="ர்"  onClick={myfn.buttonclicked}>ர்</button>
                    <button className="keyboard-button" value="169" id="ல்"  onClick={myfn.buttonclicked}>ல்</button>
                    <button className="keyboard-button" value="182" id="வ்"  onClick={myfn.buttonclicked}>வ்</button>
                    <button className="keyboard-button" value="195" id="ழ்" onClick={myfn.buttonclicked}>ழ்</button>
                    <button className="keyboard-button" value="208" id="ள்" onClick={myfn.buttonclicked}>ள்</button>
                  </div>
                <div className="second-row">
                  <div className="flex-div"></div>
                  
                  <button className="keyboard-button" value="26" id="ங்" onClick={myfn.buttonclicked}>ங்</button>
                  <button className="keyboard-button" value="52" id="ஞ்" onClick={myfn.buttonclicked}>ஞ்</button>
                  <button className="keyboard-button" value="78" id="ண்" onClick={myfn.buttonclicked}>ண்</button>
                  <button className="keyboard-button" value="104" id="ந்" onClick={myfn.buttonclicked}>ந்</button>
                  <button className="keyboard-button" value="130" id="ம்" onClick={myfn.buttonclicked}>ம்</button>
                  <button className="keyboard-button" value="234" id="ன்" onClick={myfn.buttonclicked}>ன்</button>
                  {/* <button className="keyboard-button" value="248" id="ஜ்" onClick={myfn.buttonclicked}>ஜ்</button>
                  <button className="keyboard-button" value="249" id="ஷ்" onClick={myfn.buttonclicked}>ஷ்</button>
                  <button className="keyboard-button" value="250" id="ஸ்" onClick={myfn.buttonclicked}>ஸ்</button>
                  <button className="keyboard-button" value="251" id="ஹ்" onClick={myfn.buttonclicked}>ஹ்</button> */}
                  
                  <button className="keyboard-button delfont" value="Del" onClick={myfn.delclicked}><BsBackspace/></button>
                  <button className="keyboard-button enfont" value="Enter" onClick={myfn.submitclicked}>Enter</button>
                  <div className="flex-div"></div>
                </div>
              <div className="third-row">
                  <button className="keyboard-button" value="1" id="அ" onClick={myfn.buttonclicked}>அ</button>
                  <button className="keyboard-button" value="2" id="ஆ" onClick={myfn.buttonclicked}>ஆ</button>
                  <button className="keyboard-button" value="3" id="இ" onClick={myfn.buttonclicked}>இ</button>
                  <button className="keyboard-button" value="4" id="ஈ" onClick={myfn.buttonclicked}>ஈ</button>
                  <button className="keyboard-button" value="5" id="உ" onClick={myfn.buttonclicked}>உ</button>
                  <button className="keyboard-button" value="6" id="ஊ" onClick={myfn.buttonclicked}>ஊ</button>
                  <button className="keyboard-button" value="7" id="எ" onClick={myfn.buttonclicked}>எ</button>
                  <button className="keyboard-button" value="8" id="ஏ" onClick={myfn.buttonclicked}>ஏ</button>
                  <button className="keyboard-button" value="9" id="ஐ" onClick={myfn.buttonclicked}>ஐ</button>
                  <button className="keyboard-button" value="10" id="ஒ" onClick={myfn.buttonclicked}>ஒ</button>
                  <button className="keyboard-button" value="11" id="ஓ" onClick={myfn.buttonclicked}>ஓ</button>
                  <button className="keyboard-button" value="12" id="ஔ" onClick={myfn.buttonclicked}>ஔ</button>
              </div>
            </div>
          </div>
          
          <ToastContainer/>

          

       
      </div>
     



    </div>
  );
}

export default App;
